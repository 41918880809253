let apiEndpoint = location.origin;
let authDomain = location.origin;
let templateApiEndpoint = location.origin;
if (apiEndpoint.indexOf('localhost') != -1) {
  apiEndpoint = 'https://qa.qliktag.com';
  templateApiEndpoint ='https://qa.qlkt.ag';
}
export const environment = {
  production: true,
  apiUrl: `${apiEndpoint}/api/`, //=> TEMP CHANGE V1 to V2
  templateApiUrl: `${templateApiEndpoint}/api/`,
  parentOrigin: apiEndpoint,
  googleApiKey: 'AIzaSyB_zT9a1wuMVVyztNePQLscOIW8v5wosgU',
  facebookAppId: '398787767651583',
  facebookShareUrl: 'https://www.facebook.com/dialog/feed',
  twitterShareUrl: 'https://twitter.com/intent/tweet',
  pinterestShareUrl: 'https://www.pinterest.com/pin/create/button/',
  jwt_signature: 'qlikDIseCret',
  authDomain: 'https://auth.qlkt.ag',
  firebase:{
    apiKey: "AIzaSyBsTBMaokRS2xuLJMoJnqeDKGUYWn2Dg1s",
    authDomain: "qliktag-158213.firebaseapp.com",
    projectId: "qliktag-158213",
    storageBucket: "qliktag-158213.appspot.com",
    messagingSenderId: "656316511782",
    appId: "1:656316511782:web:beacc883b4f1fbaff4d8e7",
    measurementId: "G-6JK6NJ8B9L"
  }
};
