import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToCapitalize'
})

  /**
   * [transform method to replace Camel text into Capitalize text]
   * @return string
   * @author Lalitkumar Saindane
   */
export class CamelToCapitalizePipe implements PipeTransform {

  transform(value: string, args?: any): string {

    let result;
    let output;

    if (value) {
      output = value.replace(/([A-Z])/g, ' $1');
      result = output.charAt(0).toUpperCase() + output.slice(1);
    } else {
     result = value;
    }

    return result;
  }

}
