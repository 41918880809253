export let FOOD_LANG = {
    "feature-and-benefits-about-info": [
        {
            "languageCode": "en",
            "value": "Claims listed within SmartLabel® are either governed by entities such as the USDA, FDA, EPA, etc., or defined by participating brands."
        },
        {
            "languageCode": "fr",
            "value": ""
        },
        {
            "languageCode": "en_US",
            "value": "Claims listed within SmartLabel® are either governed by entities such as the USDA, FDA, EPA, etc., or defined by participating brands."
        },
        {
            "languageCode": "en_CA",
            "value": ""
        },
        {
            "languageCode": "fr_CA",
            "value": ""
        }
    ],
    "ca-feature-and-benefits-about-info": [
        {
            "languageCode": "en",
            "value": ""
        },
        {
            "languageCode": "fr",
            "value": ""
        },
        {
            "languageCode": "en_US",
            "value": ""
        },
        {
            "languageCode": "en_CA",
            "value": "Claims listed within SmartLabel® are either governed by entities such as Health Canada, the Canadian Food Inspection Agency, Environment Canada, etc. or defined by participating brands"
        },
        {
            "languageCode": "fr_CA",
            "value": "Les allégations figurant dans SmartLabel® sont régies par des entités telles que Santé Canada, l'Agence canadienne d'inspection des aliments, Environnement Canada, etc. ou définies par les marques participantes"
        }
    ],
    "feature-and-benefits-certification-info": [
        {
            "languageCode": "en",
            "value": "SmartLabel® includes certifications from independent organizations that have meaningful and consistent standards for product composition, environmental protection and/or social justice."
        },
        {
            "languageCode": "fr",
            "value": "SmartLabel® comprend des certifications d'organisations indépendantes qui ont des normes significatives et cohérentes pour la composition des produits, la protection de l'environnement et / ou la justice sociale."
        },
        {
            "languageCode": "en_US",
            "value": "SmartLabel® includes certifications from independent organizations that have meaningful and consistent standards for product composition, environmental protection and/or social justice."
        },
        {
            "languageCode": "en_CA",
            "value": "SmartLabel® includes certifications from independent organizations that have meaningful and consistent standards for product composition, environmental protection and/or social justice."
        },
        {
            "languageCode": "fr_CA",
            "value": "SmartLabel® comprend des certifications d'organisations indépendantes qui ont des normes significatives et cohérentes pour la composition des produits, la protection de l'environnement et / ou la justice sociale."
        }
    ],
    "allergens-info": [
        {
            "languageCode": "en",
            "value": "According to the FDA, the most common food allergens are milk, peanuts, eggs, fish, crustacean shellfish, soy, tree nuts, wheat and sesame."
        },
        {
            "languageCode": "fr",
            "value": ""
        },
        {
            "languageCode": "en_US",
            "value": "According to the FDA, the most common food allergens are milk, peanuts, eggs, fish, crustacean shellfish, soy, tree nuts, wheat and sesame."
        },
        {
            "languageCode": "en_CA",
            "value": ""
        },
        {
            "languageCode": "fr_CA",
            "value": ""
        }
    ],
    "ca-allergens-info": [
        {
            "languageCode": "en",
            "value": ""
        },
        {
            "languageCode": "fr",
            "value": ""
        },
        {
            "languageCode": "en_US",
            "value": ""
        },
        {
            "languageCode": "en_CA",
            "value": "According to Health Canada, the most common food allergens are eggs, milk, mustard, peanuts, seafood (fish, crustaceans, shellfish), sesame, soy, sulfites, tree nuts, wheat."
        },
        {
            "languageCode": "fr_CA",
            "value": "Selon Santé Canada, les allergènes alimentaires les plus courants sont les œufs, le lait, la moutarde, les arachides, les fruits de mer (poisson, crustacés, crustacés), le sésame, le soja, les sulfites, les noix, le blé."
        }
    ],
    "about-this-product": [
        {
            "languageCode": "en",
            "value": "About This Product"
        },
        {
            "languageCode": "fr",
            "value": "À propos de ce produit"
        },
        {
            "languageCode": "en_US",
            "value": "About This Product"
        },
        {
            "languageCode": "en_CA",
            "value": "About This Product"
        },
        {
            "languageCode": "fr_CA",
            "value": "À propos de ce produit"
        }
    ],
    "nutritional-claims": [
        {
            "languageCode": "en",
            "value": "Nutritional Claims"
        },
        {
            "languageCode": "fr",
            "value": "Allegations Nutritionnelles"
        },
        {
            "languageCode": "en_US",
            "value": "Nutritional Claims"
        },
        {
            "languageCode": "en_CA",
            "value": "Nutritional Claims"
        },
        {
            "languageCode": "fr_CA",
            "value": "Allegations Nutritionnelles"
        }
    ],
    "dietary-claims": [
        {
            "languageCode": "en",
            "value": "Dietary Claims"
        },
        {
            "languageCode": "fr",
            "value": "Diététique Prétentions"
        },
        {
            "languageCode": "en_US",
            "value": "Dietary Claims"
        },
        {
            "languageCode": "en_CA",
            "value": "Dietary Claims"
        },
        {
            "languageCode": "fr_CA",
            "value": "Diététique Prétentions"
        }
    ],
    "health-claims": [
        {
            "languageCode": "en",
            "value": "Health Claims"
        },
        {
            "languageCode": "fr",
            "value": "Allégations Sur La Santé"
        },
        {
            "languageCode": "en_US",
            "value": "Health Claims"
        },
        {
            "languageCode": "en_CA",
            "value": "Health Claims"
        },
        {
            "languageCode": "fr_CA",
            "value": "Allégations Sur La Santé"
        }
    ],
    "regulatory-claims": [
        {
            "languageCode": "en",
            "value": "Regulatory Claims"
        },
        {
            "languageCode": "fr",
            "value": "Allégations Réglementaires"
        },
        {
            "languageCode": "en_US",
            "value": "Regulatory Claims"
        },
        {
            "languageCode": "en_CA",
            "value": "Regulatory Claims"
        },
        {
            "languageCode": "fr_CA",
            "value": "Allégations Réglementaires"
        }
    ],
    "other-claims": [
        {
            "languageCode": "en",
            "value": "Other Claims"
        },
        {
            "languageCode": "fr",
            "value": "Autres Réclamations"
        },
        {
            "languageCode": "en_US",
            "value": "Other Claims"
        },
        {
            "languageCode": "en_CA",
            "value": "Other Claims"
        },
        {
            "languageCode": "fr_CA",
            "value": "Autres Réclamations"
        }
    ],
    "warning": [
        {
            "languageCode": "en",
            "value": "Warning"
        },
        {
            "languageCode": "fr",
            "value": "Avertissements"
        },
        {
            "languageCode": "en_US",
            "value": "Warning"
        },
        {
            "languageCode": "en_CA",
            "value": "Warning"
        },
        {
            "languageCode": "fr_CA",
            "value": "Avertissements"
        }
    ],
    "certifications": [
        {
            "languageCode": "en",
            "value": "Certifications"
        },
        {
            "languageCode": "fr",
            "value": "Certifications"
        },
        {
            "languageCode": "en_US",
            "value": "Certifications"
        },
        {
            "languageCode": "en_CA",
            "value": "Certifications"
        },
        {
            "languageCode": "fr_CA",
            "value": "Certifications"
        }
    ],
    "health-safety": [
        {
            "languageCode": "en",
            "value": "Health & Safety"
        },
        {
            "languageCode": "fr",
            "value": "Santé et sécurité"
        },
        {
            "languageCode": "en_US",
            "value": "Health & Safety"
        },
        {
            "languageCode": "en_CA",
            "value": "Health & Safety"
        },
        {
            "languageCode": "fr_CA",
            "value": "Santé et sécurité"
        }
    ],
    "precautions-warnings": [
        {
            "languageCode": "en",
            "value": "Precautions / Warnings"
        },
        {
            "languageCode": "fr",
            "value": "Précautions / Avertissements"
        },
        {
            "languageCode": "en_US",
            "value": "Precautions / Warnings"
        },
        {
            "languageCode": "en_CA",
            "value": "Precautions / Warnings"
        },
        {
            "languageCode": "fr_CA",
            "value": "Précautions / Avertissements"
        }
    ],
    "advisory-statement": [
        {
            "languageCode": "en",
            "value": "Advisory Statement"
        },
        {
            "languageCode": "fr",
            "value": "Déclaration consultative"
        },
        {
            "languageCode": "en_US",
            "value": "Advisory Statement"
        },
        {
            "languageCode": "en_CA",
            "value": "Advisory Statement"
        },
        {
            "languageCode": "fr_CA",
            "value": "Déclaration consultative"
        }
    ],
    "use-by": [
        {
            "languageCode": "en",
            "value": "Use By"
        },
        {
            "languageCode": "fr",
            "value": "Utiliser par"
        },
        {
            "languageCode": "en_US",
            "value": "Use By"
        },
        {
            "languageCode": "en_CA",
            "value": "Use By"
        },
        {
            "languageCode": "fr_CA",
            "value": "Utiliser par"
        }
    ],
    "safe-handling": [
        {
            "languageCode": "en",
            "value": "Safe Handling"
        },
        {
            "languageCode": "fr",
            "value": "Manipulation sans danger"
        },
        {
            "languageCode": "en_US",
            "value": "Safe Handling"
        },
        {
            "languageCode": "en_CA",
            "value": "Safe Handling"
        },
        {
            "languageCode": "fr_CA",
            "value": "Manipulation sans danger"
        }
    ],
    "disclaimer-statement": [
        {
            "languageCode": "en",
            "value": "Disclaimer Statement"
        },
        {
            "languageCode": "fr",
            "value": "Déclaration de non-responsabilité"
        },
        {
            "languageCode": "en_US",
            "value": "Disclaimer Statement"
        },
        {
            "languageCode": "en_CA",
            "value": "Disclaimer Statement"
        },
        {
            "languageCode": "fr_CA",
            "value": "Déclaration de non-responsabilité"
        }
    ],
    "best-if-used-by": [
        {
            "languageCode": "en",
            "value": "BEST if Used By"
        },
        {
            "languageCode": "fr",
            "value": "MEILLEUR si utilisé par"
        },
        {
            "languageCode": "en_US",
            "value": "BEST if Used By"
        },
        {
            "languageCode": "en_CA",
            "value": "BEST if Used By"
        },
        {
            "languageCode": "fr_CA",
            "value": "MEILLEUR si utilisé par"
        }
    ],
    "USE-By": [
        {
            "languageCode": "en",
            "value": "USE By"
        },
        {
            "languageCode": "fr",
            "value": "UTILISER par"
        },
        {
            "languageCode": "en_US",
            "value": "USE By"
        },
        {
            "languageCode": "en_CA",
            "value": "USE By"
        },
        {
            "languageCode": "fr_CA",
            "value": "UTILISER par"
        }
    ],
    "preparation-temperature": [
        {
            "languageCode": "en",
            "value": "Preparation Temperature"
        },
        {
            "languageCode": "fr",
            "value": "Température de préparation"
        },
        {
            "languageCode": "en_US",
            "value": "Preparation Temperature"
        },
        {
            "languageCode": "en_CA",
            "value": "Preparation Temperature"
        },
        {
            "languageCode": "fr_CA",
            "value": "Température de préparation"
        }
    ],
    "storage-temperature": [
        {
            "languageCode": "en",
            "value": "Storage Temperature"
        },
        {
            "languageCode": "fr",
            "value": "Température de stockage"
        },
        {
            "languageCode": "en_US",
            "value": "Storage Temperature"
        },
        {
            "languageCode": "en_CA",
            "value": "Storage Temperature"
        },
        {
            "languageCode": "fr_CA",
            "value": "Température de stockage"
        }
    ],
    "consumption-temperature": [
        {
            "languageCode": "en",
            "value": "Consumption Temperature"
        },
        {
            "languageCode": "fr",
            "value": "Température de consommation"
        },
        {
            "languageCode": "en_US",
            "value": "Consumption Temperature"
        },
        {
            "languageCode": "en_CA",
            "value": "Consumption Temperature"
        },
        {
            "languageCode": "fr_CA",
            "value": "Température de consommation"
        }
    ],
    "product-instructions": [
        {
            "languageCode": "en",
            "value": "Product Instructions"
        },
        {
            "languageCode": "fr",
            "value": "Instructions du produit"
        },
        {
            "languageCode": "en_US",
            "value": "Product Instructions"
        },
        {
            "languageCode": "en_CA",
            "value": "Product Instructions"
        },
        {
            "languageCode": "fr_CA",
            "value": "Instructions du produit"
        }
    ],
    "usage-instructions": [
        {
            "languageCode": "en",
            "value": "Usage Instructions"
        },
        {
            "languageCode": "fr",
            "value": "Instructions d'utilisation"
        },
        {
            "languageCode": "en_US",
            "value": "Usage Instructions"
        },
        {
            "languageCode": "en_CA",
            "value": "Usage Instructions"
        },
        {
            "languageCode": "fr_CA",
            "value": "Instructions d'utilisation"
        }
    ],
    "storage-instructions": [
        {
            "languageCode": "en",
            "value": "Storage Instructions"
        },
        {
            "languageCode": "fr",
            "value": "Instructions de stockage"
        },
        {
            "languageCode": "en_US",
            "value": "Storage Instructions"
        },
        {
            "languageCode": "en_CA",
            "value": "Storage Instructions"
        },
        {
            "languageCode": "fr_CA",
            "value": "Instructions de stockage"
        }
    ],
    "feeding-guidelines": [
        {
            "languageCode": "en",
            "value": "Feeding Guidelines"
        },
        {
            "languageCode": "fr",
            "value": "Directives d'alimentation"
        },
        {
            "languageCode": "en_US",
            "value": "Feeding Guidelines"
        },
        {
            "languageCode": "en_CA",
            "value": "Feeding Guidelines"
        },
        {
            "languageCode": "fr_CA",
            "value": "Directives d'alimentation"
        }
    ],
    "nutritional-claim-statement": [
        {
            "languageCode": "en",
            "value": "Nutritional Claim Statement"
        },
        {
            "languageCode": "fr",
            "value": "Déclaration d'allégation nutritionnelle"
        },
        {
            "languageCode": "en_US",
            "value": "Nutritional Claim Statement"
        },
        {
            "languageCode": "en_CA",
            "value": "Nutritional Claim Statement"
        },
        {
            "languageCode": "fr_CA",
            "value": "Déclaration d'allégation nutritionnelle"
        }
    ],
    "no-allergens": [
        {
            "languageCode": "en",
            "value": "This product does not contain any of these common food allergens."
        },
        {
            "languageCode": "fr",
            "value": "Ce produit ne contient aucun de ces allergènes alimentaires courants."
        },
        {
            "languageCode": "en_US",
            "value": "This product does not contain any of these common food allergens."
        },
        {
            "languageCode": "en_CA",
            "value": "This product does not contain any of these common food allergens."
        },
        {
            "languageCode": "fr_CA",
            "value": "Ce produit ne contient aucun de ces allergènes alimentaires courants."
        }
    ],
    "read-more": [
        {
            "languageCode": "en",
            "value": "Read more about"
        },
        {
            "languageCode": "fr",
            "value": "En savoir plus sur les allergies"
        },
        {
            "languageCode": "en_US",
            "value": "Read more about"
        },
        {
            "languageCode": "en_CA",
            "value": "Read more about"
        },
        {
            "languageCode": "fr_CA",
            "value": "En savoir plus sur les allergies"
        }
    ],
    "read-allergies": [
        {
            "languageCode": "en",
            "value": "allergies on"
        },
        {
            "languageCode": "fr",
            "value": "sur"
        },
        {
            "languageCode": "en_US",
            "value": "allergies on"
        },
        {
            "languageCode": "en_CA",
            "value": "allergies on"
        },
        {
            "languageCode": "fr_CA",
            "value": "sur"
        }
    ],
    "feature-and-benefits": [
        {
            "languageCode": "en",
            "value": "Features & Benefits"
        },
        {
            "languageCode": "fr",
            "value": "Caractéristiques et Avantages"
        },
        {
            "languageCode": "en_US",
            "value": "Features & Benefits"
        },
        {
            "languageCode": "en_CA",
            "value": "Features & Benefits"
        },
        {
            "languageCode": "fr_CA",
            "value": "Caractéristiques et Avantages"
        }
    ],
    "preparation-instructions": [
        {
            "languageCode": "en",
            "value": "Preparation Instructions"
        },
        {
            "languageCode": "fr",
            "value": "Instructions de préparation"
        },
        {
            "languageCode": "en_US",
            "value": "Preparation Instructions"
        },
        {
            "languageCode": "en_CA",
            "value": "Preparation Instructions"
        },
        {
            "languageCode": "fr_CA",
            "value": "Instructions de préparation"
        }
    ],
    "privacy-policy": [
        {
            "languageCode": "en",
            "value": "Privacy Policy"
        },
        {
            "languageCode": "fr",
            "value": "Politique de confidentialité"
        },
        {
            "languageCode": "en_US",
            "value": "Privacy Policy"
        },
        {
            "languageCode": "en_CA",
            "value": "Privacy Policy"
        },
        {
            "languageCode": "fr_CA",
            "value": "Politique de confidentialité"
        }
    ],
    "smartlabel-feedback": [
        {
            "languageCode": "en",
            "value": "Feedback for Smartlabel"
        },
        {
            "languageCode": "fr",
            "value": "Commentaires sur Smartlabel"
        },
        {
            "languageCode": "en_US",
            "value": "Feedback for Smartlabel"
        },
        {
            "languageCode": "en_CA",
            "value": "Feedback for Smartlabel"
        },
        {
            "languageCode": "fr_CA",
            "value": "Commentaires sur Smartlabel"
        }
    ]
}       