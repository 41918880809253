import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { ActionSheetController } from '@ionic/angular';
import { ActionSheetButton } from '@ionic/core';
@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
})
export class FabComponent implements OnInit,AfterViewInit {
  @Input() menuItems: Array<ActionSheetButton> = [];
  @Input() config: any = {}
  @ViewChild('fab', { read: ElementRef }) fab;
  closeActionSheetMenu: boolean = false;
  

  constructor(
    private pageService: PageService,
    public actionSheetController: ActionSheetController,
  ) { }

  ngOnInit() {
   
    
  }

  ngAfterViewInit() {
   
    if(this.fab) {
      if(this.pageService.styles) {
        
       if(this.pageService.styles.menu) {
         const fabBgColor = this.pageService.styles.menu.fabBgColor;
      
         this.fab.nativeElement.style.setProperty('--background', fabBgColor); 
         this.fab.nativeElement.style.setProperty('--background-hover', fabBgColor); 
        
         
       }
      
     }
     
   }
  }

  /**
   * Method to show actionsheet menu
   * @author - Abhishek
   * @task - QLIKTAG-3150
   */
   async showMenuActionSheet() {

    let header = 'Menu';
    if(this.config.hasOwnProperty('header')) {
      header = this.config.header;
    }


    const actionSheet = await this.actionSheetController.create({
      header,
      buttons: this.menuItems,
      mode: "md"
    });

    await actionSheet.present()
      .then(() => {
        this.closeActionSheetMenu = true;
      })

    actionSheet.onWillDismiss()
      .then(() => {
        this.closeActionSheetMenu = false;
      });
  }

}
