import { Pipe, PipeTransform } from '@angular/core';
import { PageService } from '../services/page.service';

@Pipe({
  name: 'trans'
})
export class TransPipe implements PipeTransform {

  constructor(private pageService: PageService) {

  }

  /**
   * Return listItemCode translations 
   * @author Neil C 
   * @task QLIKTAG-4067 
   * @param value 
   * @param args 
   * @returns 
   */
  transform(value: any, ...args: any[]): any {

    if(this.pageService.translations.length) {

     const listItem = this.pageService.translations.find(({listItemCode}) => listItemCode === value);
     if(listItem) {
       const { listItemName } = listItem;

       return listItemName;
     }

    }

    return null;
  }

}
