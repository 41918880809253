import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { PageService } from '../services/page.service';
import { AppService } from '../services/app.service';
import { MultiLangPipe } from '../pipes/multi-lang.pipe';
import { ActivatedRoute } from '@angular/router';
import { isObject } from 'lodash';
import { AssetTypePipe } from './asset-type.pipe';


@Pipe({
  name: 'shortCode'
})
@Injectable()
export class ShortCodePipe implements PipeTransform {

  constructor(
    private pageService: PageService,
    private appService: AppService,
    private multiLangPipe: MultiLangPipe,
    private activatedRoute: ActivatedRoute,
    private assetTypePipe: AssetTypePipe,
   
  ) {}

   sources = ['EI', 'DC', 'CA', 'QP', 'SL', 'LV'];
   langCode = this.pageService.diLanguages;

  /**
   * [transform method to replace {{A.B.C}} into text]
   * @param text contains string
   * @returns any value
   * @author Lalitkumar Saindane
   */
  transform(text: string, dataSource?: string): any {
    const rgxValue = /({{)([\w\.\s\|:]+)(}})/g;

   
   
    let matches = [];
    if (text) {      
      matches = text.toString().match(rgxValue);
      if (Array.isArray(matches)) {
        for (const item of matches) {
          text = text.replace(item, this.getValue(item, dataSource));
        }
      }
    } else {
      text = '';
    }
    return text;
  }

  /**
   * function to search and replace shortcode with actual value from source
   * @param str contains string
   * @returns any value
   * @author Lalitkumar Saindane
   */
  getValue(str: string, dataSource?:string): any {
    let code: string;
    let result: number | boolean | string  = '';
    let source = 'EI';
    let language: string;
    let data: object;
    // Removing {{}}
    code = str.substring(2 , str.length - 2);
    const [fieldPath , ...filters] = code.split('|').map(i => i.trim());
    if (fieldPath) {
      let codeArr = fieldPath.split('.');
      // Pluck source
      [codeArr, source] = this.extractSource(codeArr);
      // Pluck languageCode
      [codeArr, language] = this.extractLang(codeArr);

      // Data access base on sources
      if (source === 'EI') {

        data = this.pageService.diData;
        if (data) {
          const value = this.appService.findObjectByPath(data, codeArr);
          result = this.getResult(value, language);
        }
      } else if (source === 'LV') { //Template Local Variables
        data = this.pageService.lv;
        if (data) {
          const value = this.appService.findObjectByPath(data, codeArr);
          result = this.getResult(value, language);
        }

      }else if (source === 'SL') { //smartlabel
        data = this.pageService.smartLabelData;
        if (data) {
          const value = this.appService.findObjectByPath(data, codeArr);
          result = this.getResult(value, language);
        }

      } else if (source === 'CA') {
        data = this.pageService.customGetAPIData;
        if (data) {
          const value = this.appService.findObjectByPath(data, codeArr);
          result = this.getResult(value, language);
        }
      } else if (source === 'DC') {
        // Data channel access method pending
        // data = this.pageService.
      } else if (source === 'QP') {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        result = '';
        if (codeArr.length) {
          const [key] = codeArr;
          if (queryParams.hasOwnProperty(key)) {
            result = queryParams[key];
          }
        }
      }
      // console.log(result);
      // Apply list of filters if result is present
      if (result) {
        // console.log(filters);
        filters.forEach(filter => {
          if (filter) {
            const [type, ...params] = filter.split(':').map(i => i.trim());
            switch (type) {
              case 'mapTo':
                let listItem;
                if ((source === 'EI'|| source === 'SL') && typeof result === 'string') {
                  listItem = this.searchListItemMap(result, codeArr.join('.'));
                  let fieldArr = params[0].split('.').map(i => i.trim());
                  [fieldArr, language] = this.extractLang(fieldArr);
                  // console.log(listItem, fieldArr);
                  const value = listItem ? this.appService.findObjectByPath(listItem, fieldArr) : '';
                  result = this.getResult(value, language);
                } else if (isObject(result) && result === Object(result)) {
                  let fieldArr = params[0].split('.').map(i => i.trim());
                  [fieldArr, language] = this.extractLang(fieldArr);
                  const value = result ? this.appService.findObjectByPath(result, fieldArr) : '';
                  result = this.getResult(value, language);
                }
              break;
              case 'upperCase':
                if (typeof result === 'string') {
                  result = result.toUpperCase();
                }
              break;
              case 'find':
                // {{ result | find: fieldValue: logo: 2 }}
                if (Array.isArray(result) && result.length) {
                  const [property, value, position] = params;
                  const matched = result.filter((item) => {
                    if (item.hasOwnProperty(property)) {
                      return item[property] === value;
                    }
                  });
                  // console.log(matched);
                  if (matched.length) {
                    result = matched[0];
                  }
                }
              break;
            }
          }
        });
      }  // filters
    }
    return result;
  }

  /**
   * [extractSource checks if first segment is valid data source and separates it from path ]
   * @param  pathArr [ path in form of array of string ]
   * @return         [ tuple of path array and extracted source ]
   * @author - SwapnilP
   * @task - QLIKTAG-3292
   */
  extractSource(pathArr: Array<string> = []): [ Array<string>, string ] {
    let source = 'EI';
    if (pathArr.length > 0) {
      const firstSegment =  pathArr[0] || '';
      const isSource = this.sources.includes(firstSegment);
      if (firstSegment && isSource) {
        pathArr.shift();
        source = firstSegment;
      }
    }
    return [pathArr, source];
  }

  /**
   * [extractLang checks if last segment is valid language code and separates it from path ]
   * @param  pathArr [ path in form of array of string ]
   * @return         [ tuple of path array and extracted langauge code ]
   * @author - SwapnilP
   * @task - QLIKTAG-3292
   */
  extractLang(pathArr: Array<string> = []): [ Array<string>, string ] {
    let language = this.pageService.appLang$.getValue();
    if (pathArr.length > 0) {
      const lastSegment =  pathArr[pathArr.length - 1] || '';
      const isLangCode = this.langCode.includes(lastSegment.toLowerCase());
      if (lastSegment && isLangCode) {
        pathArr.pop();
        language = lastSegment.toLowerCase();
      }
    }
    return [pathArr, language];
  }
  /**
   * [getResult if value is Array , get value dpeneding on language. else return as it is ]
   * @param  value    [ value - multilingual Array of values, or direct values ]
   * @param  language [ language to cast values]
   * @return          [ Value corresponding to given language or same array if array is not multilingual ]
   * @author - SwapnilP
   * @task - QLIKTAG-3292
   */
  getResult(value, language: string): any {
    let result: any = '';
    if (value != undefined) {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean'  ) {
        result = value;
      } else if (this.isAssetType(value)) {
        result = this.assetTypePipe.transform(value);
      } else if (Array.isArray(value)) {
        if (this.isArrayMultiLingual(value)) {
          result = this.multiLangPipe.transform(value, language, true);
        } else {
          result = value;
        }
      }
    }
    return result;
  }

  /**
   * [isArrayMultiLingual Checks if array is multilingual]
   * @param  arr [ Array to be checked for multilingual ]
   * @return       [ true if array is multilinual, else false]
   * @author - SwapnilP
   * @task - QLIKTAG-3292
   */
  isArrayMultiLingual(arr: Array<any> = []): boolean {
    return arr.some(item => {
      return item.hasOwnProperty('value') && item.hasOwnProperty('languageCode');
    });
  }

  /**
   * [isAssetType checks object or array , if it is assetType ]
   * @param  node [ object / array ]
   * @return      [ if isAssetType true else false ]
   * @task - QLIKTAG-3785
   * @author - SwapnilP
   */
  isAssetType(node: any): boolean {
    let result = false;
    if (Array.isArray(node)) {
      result = node.some(item => {
        return item.hasOwnProperty('type') && item.hasOwnProperty('url') && item.hasOwnProperty('languageCode');
      });
    } else {
      if (node.hasOwnProperty('type') && node.hasOwnProperty('url') && node.hasOwnProperty('languageCode')) {
        result = true;
      }
    }
    return result;
  }

  /**
   * [ searchListItemMap Search object in listItemMap using listitemCode and path ]
   * @param  listItemCode [ listItemCode i.e 'ONZ']
   * @param  path         [ dot separated path in string i.e. a.b.c]
   * @return              [ Returns single listItem which matches both listitemCode and path ]
   * @author - SwapnilP
   * @task - QLIKTAG-3292
   */
   searchListItemMap(listItemCode: string, path: string): any {
     let bucket = [];
     let listItem;
     if (listItemCode) {
       bucket = this.pageService.listItemMap[listItemCode];
     }
     if (path && bucket) {
       listItem = bucket.find(item => item.fieldPath.filter(fieldPath => fieldPath === path));
     }
     return listItem;
   }
}
