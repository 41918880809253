

<ion-content>
  <div class="sign-page">
    <div class="message">
      <div class="text-center f-80"><ion-icon name="lock" color="medium"></ion-icon></div>

      <ng-template [ngIf]="!inProgress && hasError">
        <div class="errors m-b-10 m-t-10">
          <p color="danger">Something went wrong</p>
          <ion-button  mode="ios" color="light" *ngIf="interactionUrl" (click)="backToInteraction()">Go Back</ion-button>
        </div>
      </ng-template>
      
      <app-loading *ngIf="inProgress"></app-loading>
    </div>
  </div>
</ion-content>
