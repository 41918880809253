<ng-template [ngIf]="config">
  <ion-footer>
    <ion-toolbar #toolbar>
     

      <ng-template [ngIf]="config?.logo.url">
        <ion-img [src]="config.logo.url" [slot]="config.logo.align"
        [style.width.px]="config.logo.width"
        [style.height.px]="config.logo.height"
        [style.margin-left.px]="20"
        [style.margin-right.px]="20"
        [class.centered]="config.logo.align===''"
        ></ion-img>
      </ng-template>


     
        <ion-title #footerTitle [innerHTML]="title" [slot]="config.footerText.align" [class]="footerStyles.textClass"
        [class.ion-text-center]="config.footerText.align===''" *ngIf="title!=''">
        </ion-title> 
     




    </ion-toolbar>
  </ion-footer>
</ng-template>