import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { PageService } from 'src/app/services/page.service';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { MultiLangPipe } from 'src/app/pipes/multi-lang.pipe';
MultiLangPipe
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PushNotificationsService } from 'src/app/services/push-notifications.service';
import { PushNotificationsAccessComponent } from '../push-notifications-access/push-notifications-access.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, AfterViewInit {
  @Input() config: any = {};
 
  isMenuButtonVisible = false;
  languages = [];
  headerStyles = {
    background: '#ffffff',
    height: '56',
  };
  title: string | number | boolean = '';
  color: string = "#000000";
  @ViewChild('toolbar', { read: ElementRef }) toolbar: ElementRef<any>;
  @ViewChild('headerTitle', { read: ElementRef }) headerTitle:ElementRef<any>;

  isLogoutInProgress: boolean = false;
  constructor(
    private renderer: Renderer2,
    private popoverController: PopoverController,
    private pageService: PageService,
    private safePipe: SafePipe,
    private multiLangPipe: MultiLangPipe,
    public sanitizer: DomSanitizer,
    public pushNotificationsService: PushNotificationsService,
    public modalController: ModalController,
    public authService: AuthService,

  ) { }

  ngOnInit() {
   // console.log('Header Config', this.config);

   
    if (this.config) {
      
      if (this.config.bgColor) {

        this.headerStyles.background = this.config.bgColor
      }

      if(this.config.backgroundImage){

        let { url } = this.config.backgroundImage;

        if(url){
          this.headerStyles.background = `url('${url}') no-repeat 0 0 /cover ${this.headerStyles.background}`;
        }
        
      }



      if (this.config.headerHeight) {
        this.headerStyles.height = this.config.headerHeight
      }


      if (this.config.headerText) {
        this.title =  this.multiLangPipe.transform(this.config.headerText.text , this.pageService.appLang$.getValue());
      }

      if(this.config.color != '') {
        this.color = this.config.color;
      }

     // console.log('Header styles',this.headerStyles)
    }
    // set languages
    this.languages = this.pageService.appLanguages;
    // set menu bar visibility
    if (this.pageService.diLayout.hasOwnProperty('pages') && this.pageService.diLayout.pages) {
      this.isMenuButtonVisible = this.pageService.navigationType == '1' && (this.pageService.diLayout.pages.length > 1);
    }
  }

  ngAfterViewInit(): void {
    if (this.toolbar && this.toolbar.hasOwnProperty('nativeElement')) {
      this.renderer.setStyle(this.toolbar.nativeElement, 'cssText', `--background: ${this.headerStyles['background']}; --min-height: ${this.headerStyles["height"]}px`);
    }

    if (this.headerTitle && this.headerTitle.hasOwnProperty('nativeElement')) {
      this.renderer.setStyle(this.headerTitle.nativeElement, 'cssText', `--color: ${this.color}`);
    }
  }

    /**
   * [Method to present popover of language list]
   * @task QLIKTAG-3253
   * @author Abhishek
   */
  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: LanguageSelectorComponent,
      componentProps: {
        languages: this.languages,
        currentLang: this.pageService.appLang$.getValue(),
        templateLanguages: this.pageService.diLanguages
      },
      event: ev,
      translucent: true
    });
    return await popover.present();
  }


  openPushNotificationsModal() {
    this.showPushNotificationModal(this.pushNotificationsService.config);
    this.pushNotificationsService.isPushNotificationModalOpen = true;
  }


  async showPushNotificationModal({config, data, controllerCode, environment, ditemplateId}) {

    const modal = await this.modalController.create({
      component: PushNotificationsAccessComponent,
      cssClass: 'push-notifications-access-modal',
      componentProps: {
        config,
        data,
        controllerCode,
        environment,
        ditemplateId
      }
    });
    return await modal.present();
  }

  logout() {
    this.isLogoutInProgress = true;
    this.authService.logout(true)
    

  }
   
}
