import { Injectable } from '@angular/core';
import { Meta } from "@angular/platform-browser";

@Injectable()
export class SeoService {

  constructor(private meta: Meta) { 
  }

 /**
  * get induvidual tag for given attribute selector
  * @param attributeSelector [attribute string ex. 'name="og:title"'] 
  * @task QLIKTAG-3003
  * @author Radhika Agnihotri
  */
  getTag(attributeSelector: string) {
    let ele : HTMLMetaElement = this.meta.getTag(attributeSelector)
    //console.log('getTag name',ele.name, 'getTag content',ele.content)
  }
  
  /**
   * get all tags with given attribute selector
   * @param attributeSelector [attribute ex 'name']
   */
  getTags(attributeSelector: string) {
    let ele: HTMLMetaElement[] = this.meta.getTags(attributeSelector);
    //ele.forEach((item)=>console.log('getTags name',item.getAttribute(attributeSelector)))
  }
 
  /**
   * add tag for given metadata
   * @param metadataObj [object with attribute and value {name: "description", content:"Description of page"}]
   * @task QLIKTAG-3003
   * @author Radhika Agnihotri
   */
  addTag(metadataObj: any) {
    this.meta.addTag(metadataObj);
  }
  /**
   * add multiple tags 
   * @param tagsArray [array of metadataObj ex.[{name: "description", content:"Description of page"}]]
   * @task QLIKTAG-3003
   * @author Radhika Agnihotri
   */
  addTags(tagsArray: Array<any>) {
    this.meta.addTags(tagsArray);
  }
  /**
   * update existing tag
   * @param metadataObj [object with attribute and value {name: "description", content:"Description of page"}]
   * @task QLIKTAG-3003
   * @author Radhika Agnihotri
   */
  updateTag(metadataObj: any) {
    this.meta.updateTag(metadataObj)
  }
  /**
   * remove existing tag by attribute selector
   * @param attributeSelector [attribute string ex. 'name="og:title"'] 
   * @task QLIKTAG-3003
   * @author Radhika Agnihotri
   */
  removeTag(attributeSelector: string) {
    this.meta.removeTag(attributeSelector)
  }
  /**
   * remove tag element 
   * @param attributeSelector [attribute string ex. 'name="og:title"'] 
   * @task QLIKTAG-3003
   * @author Radhika Agnihotri
   */
  removeTagElement(attributeSelector: string) {
    let tagElement: HTMLMetaElement = this.meta.getTag(attributeSelector);
    this.meta.removeTagElement(tagElement);
    //console.log('Tag element removed', tagElement);
  }
}
