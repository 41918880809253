import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { MultiLangPipe } from 'src/app/pipes/multi-lang.pipe';
import { PageService } from 'src/app/services/page.service';
PageService

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit, AfterViewInit {
  @Input() config: any = {};
  @ViewChild('toolbar', { read: ElementRef }) toolbar: ElementRef<any>;
  @ViewChild('footerTitle', { read: ElementRef }) footerTitle: ElementRef<any>;

  footerStyles = {
    background: '#ffffff',
    height: '56',
  };
  title: string | number | boolean = '';
  color: string = '#000000';
  constructor(private renderer: Renderer2,
    private pageService: PageService,
    private multiLangPipe: MultiLangPipe) { }



  ngOnInit() {
    //console.log('Footer Config', this.config);

    if (this.config) {
      if (this.config.bgColor) {
        this.footerStyles.background = this.config.bgColor
      }

      if (this.config.footerHeight) {
        this.footerStyles.height = this.config.footerHeight
      }

      if (this.config.footerText) {
        this.title = this.multiLangPipe.transform(this.config.footerText.text, this.pageService.appLang$.getValue());
      }

      if (this.config.color != '') {
        this.color = this.config.color;
      }

      //console.log('Footer styles',this.footerStyles)
    }
  }

  ngAfterViewInit() {
    if (this.toolbar && this.toolbar.hasOwnProperty('nativeElement')) {
      this.renderer.setStyle(this.toolbar.nativeElement, 'cssText', `--background: ${this.footerStyles['background']}; --min-height: ${this.footerStyles["height"]}px`);
    }


    if (this.footerTitle && this.footerTitle.hasOwnProperty('nativeElement')) {
      this.renderer.setStyle(this.footerTitle.nativeElement, 'cssText', `--color: ${this.color}`);
    }
  }
}
