<ion-app [ngClass]="{'sandbox':isSandboxMode}" [markjsHighlight]="appService.searchText$ | async"
  [markjsConfig]="searchConfig" [scrollToFirstMarked]="false">

  <div padding text-center class="error-page no-support text-center padding ion-padding"
    *ngIf="unsupported; else supported">
    <div class="message">
      <h1>
        <fa-icon [icon]="['fas', 'ban']" slot="end"></fa-icon>
      </h1>
      <h1>Your browser is not supported </h1>
      <p>We're sorry but our platform has been built on newer technologies which may not be supported by your browser &
        impact your experience.</p>
      <p>Please download & upgrade your browsers to one of these newer browsers for a better experience.</p>
      <p class="browser-logos">
        <a href="https://www.google.com/chrome/"><img src="../assets/img/browsers/chrome.svg" width="60"
            height="60" /></a>
        <a href="https://www.mozilla.org/firefox/new/"><img src="../assets/img/browsers/firefox.svg" width="60"
            height="60" /></a>
        <a href="https://www.apple.com/safari/"><img src="../assets/img/browsers/safari.svg" width="60"
            height="60" /></a>
        <a href="https://www.microsoft.com/windows/microsoft-edge"><img src="../assets/img/browsers/edge.svg" width="60"
            height="60" /></a>
      </p>
    </div>
  </div>

  <ng-template #supported>
    <app-loading *ngIf="appService.isLoading"></app-loading>

    <router-outlet></router-outlet>
  </ng-template>

</ion-app>