import { Injectable } from '@angular/core';
import { InteractionTemplateResolver } from './interaction-template.resolver';
import {
  AuthResolver
} from './auth.resolver';
import { Resolve } from '@angular/router';
import { from } from 'rxjs';
import { AppService } from '../services/app.service';


@Injectable({
  providedIn: 'root'
})
export class InteractionResolver implements Resolve<{ template: any, authData: any }> {
  constructor(
    protected interactionTemplateResolver: InteractionTemplateResolver,
    protected authResolver: AuthResolver,
    private appService: AppService
  ) { }

  /**
   * Combined Interaction Template Resolver + Auth Resolver
   * @param route 
   * @param state 
   * @author Neil C 
   * @QLIKTAG-4052
   * @returns 
   */
  async resolve(route, state) {
    //console.log(route, state)
    this.appService.isLoading = true;
    const template = await from(this.interactionTemplateResolver.resolve(route, state)).toPromise();
    const authData = await from(this.authResolver.resolve(route, template)).toPromise();
    //console.log(template, authData);
    return { template, authData };
  }
}