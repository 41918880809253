<ion-content>


  <div padding text-center class="error-page ion-padding ion-text-center">
    <div class="message">
      <h1>{{code}}</h1>
      <h2>Oops, The page you are looking for can't be found!</h2>
      <!-- <a href="https://www.qliktag.com/" title="QLIKTAG">Go Back</a> -->
    </div>
  </div>

</ion-content>