import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { LanguageSelectorComponent } from '../../components/language-selector/language-selector.component';
import { MultiLangPipe } from '../../pipes/multi-lang.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { Code2txtPipe } from '../../pipes/code2txt.pipe';
import { NetContentPipe } from '../../pipes/net-content.pipe';
import { SeoService } from '../../services/seo.service';
import { DiDatePipe } from '../../pipes/di-date.pipe';
import { CamelToCapitalizePipe } from '../../pipes/camel-to-capitalize.pipe';

import { ShortCodePipe } from '../../pipes/short-code.pipe';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from "@angular/forms";
import { FroalaViewModule } from 'angular-froala-wysiwyg';
import { PopoverComponent } from '../../components/popover/popover.component';
import { MainFooterComponent } from '../../components/main-footer/main-footer.component';
import { MainHeaderComponent } from '../../components/main-header/main-header.component';
import { AssetTypePipe } from '../../pipes/asset-type.pipe';
import { FabComponent } from 'src/app/components/fab/fab.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataService } from 'src/app/services/data.service';
import { TransPipe } from 'src/app/pipes/trans.pipe';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { LinkyModule, LinkyPipe } from 'ngx-linky';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MultiLangPipe,
    SafePipe,
    Code2txtPipe,
    NetContentPipe,
    DiDatePipe,
    CamelToCapitalizePipe,
    ShortCodePipe,
    LanguageSelectorComponent,
    PopoverComponent,
    MainFooterComponent,
    MainHeaderComponent,
    AssetTypePipe,
    FabComponent,
    TransPipe,


  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,

    ReactiveFormsModule,
    FroalaViewModule.forRoot(),
    NgSelectModule,
    FontAwesomeModule,
    LinkyModule


  ],
  exports: [MainHeaderComponent, MainFooterComponent, FroalaViewModule, HeaderComponent, FooterComponent, MultiLangPipe, SafePipe, Code2txtPipe, NetContentPipe, DiDatePipe, CamelToCapitalizePipe, ShortCodePipe, FabComponent, NgSelectModule, FontAwesomeModule, LinkyModule],
  providers: [MultiLangPipe, Code2txtPipe, SeoService, CamelToCapitalizePipe, DiDatePipe, ShortCodePipe, SafePipe, AssetTypePipe, TransPipe, DataService, LinkyPipe],
  entryComponents: [LanguageSelectorComponent, PopoverComponent]
})
export class SharedModule {

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIconPacks(far);
  }
}
