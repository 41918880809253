import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { LayoutComponent } from './layout/layout.component';
import { InteractionResolver } from './resolvers/interaction.resolver';
const routes: Routes = [
  {
    path: 'lp', // In case of sandbox i.e sb, iid is not needed
    data: { itype: 'lp' },
    children: [
      {
        path: ':iid',
        children:
          [
            {
              path: ':eid',
              resolve: { interactionData: InteractionResolver },
              component: LayoutComponent,
              loadChildren: () => import('./modules/main/main.module').then(m => m.MainPageModule)
            },
            {
              path: '',
              resolve: { interactionData: InteractionResolver },
              component: LayoutComponent,
              loadChildren: () => import('./modules/main/main.module').then(m => m.MainPageModule)
            }
          ]
      },
      {
        path: '',
        component: ErrorComponent
      }
    ]
  },
  {
    path: 'dl', // In case of sandbox i.e sb, iid is not needed
    data: { itype: 'dl' },
    children: [
      {
        path: ':eid',
        children:
          [
            {
              path: '',
              resolve: { interactionData: InteractionResolver },
              component: LayoutComponent,
              loadChildren: () => import('./modules/main/main.module').then(m => m.MainPageModule)
            }
          ]
      },
      {
        path: '',
        component: ErrorComponent
      }
    ]
  },
  {
    path: 'error',
    children: [
      {
        path: ':code',
        component: ErrorComponent
      },
      {
        path: '',
        component: ErrorComponent
      },
    ]
  },
  {
    path: "sign-in",
    //resolve: { appLanguages:  },
    component: SignInComponent,


  },
  {
    path: ":shortcode",
    resolve: { interactionData: InteractionResolver },
    component: LayoutComponent,
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainPageModule),

  },





  // {
  //   path: ':shortcode/p/:pageName',
  //   resolve: { template: InteractionTemplateResolver, appLanguages: LanguageResolver },
  //   component: LayoutComponent,
  //   loadChildren: './modules/main/main.module#MainPageModule'
  // },
  // {
  //   path: ':shortcode',
  //   resolve: { template: InteractionTemplateResolver, appLanguages: LanguageResolver },
  //   component: LayoutComponent,
  //   loadChildren: './modules/main/main.module#MainPageModule'
  // },
  {
    path: '',
    redirectTo: '/error/404',
    pathMatch: 'full'
  },
  // {
  //   path: 'lp/:iid',  // For other itype i.e lp / dc / ca, iid is must, eid is optional.
  //   data: { itype: 'lp' },
  //   children: [
  //     {
  //       path: ':eid',
  //       component: LayoutComponent,
  //       resolve: { layout: InteractionTemplateResolver },
  //       loadChildren: './modules/main/main.module#MainPageModule'
  //     },
  //     {
  //       path: '',
  //       component: LayoutComponent,
  //       resolve: { layout: InteractionTemplateResolver },
  //       loadChildren: './modules/main/main.module#MainPageModule'
  //     }
  //   ]
  // },
  // {
  //   path: ':eid',   // when itype is not present
  //   component: LayoutComponent,
  //   resolve: { layout: InteractionTemplateResolver },
  //   loadChildren: './modules/main/main.module#MainPageModule'
  // },
  // {
  //   path: '**',   // Key value pairs
  //   component: LayoutComponent,
  //   resolve: { layout: InteractionTemplateResolver },
  //   loadChildren: './modules/main/main.module#MainPageModule'
  // }
];




@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
