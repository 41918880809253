import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SmartLabelService } from '../components/smart-label/smart-label.service';
import { AppService } from './app.service';
import { PageService } from './page.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GatemanService {
  /**
  * @author - SwapnilP
  * @task - QLIKTAG-2925
  */

  public certificationPresent$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private router: Router,
    private appService: AppService,
    private pageService: PageService,
    private smartLabelService: SmartLabelService,
    private location: Location) { }
  /**
   * [Checks pageMode and decides which routing function to call]
   * @param  url    [String url]
   * @param  config [Extra configuration for naviagation]
   * @return        [nothing]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  routeHub(url: string, config: any = {}) {
    // If relative to is present in pageRoute
    const mode = this.pageService.pageMode;
    if (mode === 'smartlabel') {
      this.smartLabelService.smartLabelRouteHub(url, config);
    } else {
      this.routeMe(url, config);
    }
  }
  
  /**
   * [Routing function for non smartlabel pages]
   * @param  url    [string url]
   * @param  config [Extra configuration for naviagation]
   * @return        [nothing]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  routeMe(url: string, config: any) {
    const target = config.target || '_self';
    if (url) {
      if (url.startsWith('www') || url.startsWith('http') || url.startsWith('mailto') || url.startsWith('tel')) {
        window.open(url, target);
      } else {
        const pageQueryParams = ['resetQueryParams', 'listIndex', 'detail'];
        const segments = this.appService.getSegments(url);
        url = segments.url ? segments.url : '';
        const queryString = segments.queryParams ? segments.queryParams : '';
        const queryParams = this.buildQueryParams(queryString);
        const fragment = segments.fragment ? segments.fragment : null;
        config.queryParams = {...config.queryParams, ...queryParams};
        if (config.queryParams.resetQueryParams) {
          for (const key of pageQueryParams) {
            config.queryParams[key] = null;
          }
        }
        const lpRoute = this.getLandingPageRoute();
        url = this.location.normalize(Location.joinWithSlash(lpRoute, `${url}`));
        this.router.navigate( [url], { queryParams: config.queryParams, queryParamsHandling: 'merge', state: config.data, fragment: fragment, preserveFragment: config.preserveFragment });
      }
    }
  }
  
  /**
   * [Function to find p in url and return path before /p/:alias]
   * @return [ full string url of a page ]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  getLandingPageRoute(url: string = this.router.url): string {
    let current = url;
    // sb/1/2/p/smartlabel#fragment?q=1 => [sb/1/2/p/smartlabel, fragment, q=1]
    // sb/1/2/p/smartlabel#fragment?q=1 => [sb/1/2/p/smartlabel, q=1]
    // sb/1/2/p/smartlabel => [sb/1/2/p/smartlabel]
    // Splits on both ? and #
    current = current.split(/[?|#]/)[0];
    const segments = current.split('/');
    const index = segments.indexOf('p');
    if (index !== -1 ) {
      return segments.splice(0, index).join('/');
    } else {
      return segments.join('/');
    }
  }
  
  /**
   * [buildQueryParams Converts query string to object]
   * @param  queryString [string a=b&c=d]
   * @return             [{a: b, c: d}]
   * @author - SwapnilP
   * @task - QLIKTAG-2925
   */
  buildQueryParams(queryString: string) {
    let result = {};
    if (queryString) {
      result = queryString.split('&').reduce((acc, curr) => {
        const [key, val] = curr.split('=');
        acc[key] = val;
        return acc;
      }, {});
    }
    return result;
  }
}
