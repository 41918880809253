export const GHS = {
    'ghs_skin_irritant': {
        'title': 'GHS Skin Irritant.',
        'description': 'A product classified according to the Globally Harmonized System for Classification and Labeling of Chemicals (GHS) Chapter 3.2, Skin Corrosion/Irritation, as a Category 1, 2 or 3 skin corrosive or skin irritant. A product should be classified as corrosive to the skin if it has a pH of 2 or less or a pH of 11.5 or greater, unless tested or proven otherwise.'
    },
    'ghs_eye_irritant': {
        'title': 'GHS Eye Irritant.',
        'description':'A product classified according to GHS Chapter 3.3, Serious Eye Damage/Eye Irritation, as a Category 1 or 2 eye irritant. A product should be classified as capable of causing serious eye damage if it has a pH of 2 or less or a pH of 11.5 or greater, unless tested or proven otherwise.'
    },
    'ghs_respiratory_or_skin_sensitizer': {
        'title': 'GHS Respiratory or Skin Sensitizer.',
        'description': 'A product classified according to GHS Chapter 3.4, Respiratory and Skin Sensitization, as Category 1A - High frequency of occurrence or sensitization rate in humans; or Category 1B – Low to moderate frequency of occurrence or sensitization rate in humans.'
    },
    'ghs_mutagen': {
        'title': 'GHS Mutagen.',
        'description': 'A product classified according to GHS Chapter 3.5, Germ Cell Mutagenicity, as Category 1A - Chemicals known to induce heritable mutations in the germ cells of humans; or Category 1B - Chemicals which should be regarded as if they induce heritable mutations in the germ cells of humans.'
    },
    'ghs_aquatic_toxin': {
        'title': 'GHS Aquatic Toxin.',
        'description':' A product classified according to GHS Chapter 4.1, Hazardous to the Aquatic Environment, as a Category 1, 2 or 3 acute or chronic aquatic toxin with a median lethal concentration (LC50) of less than 100 milligrams per liter.'
    }
};