import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { PageService } from '../services/page.service';

@Injectable({
  providedIn: 'root'
})
export class AuthResolver implements Resolve<any> {

  route: ActivatedRouteSnapshot;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private pageService: PageService) {
  }

  /**
   * Get oauth data if _ukey parameter is present in the url
   * @param route 
   * @param templateData from interaction-template.resolver
   * @author Neil C 
   * @task QLIKTAG-4052
   * @returns 
   */
  resolve(route: ActivatedRouteSnapshot, templateData) {

    try {

      const { _ukey } = route.queryParams;

      if(templateData) {
        const { vdi } = templateData;
        if (vdi) {
          const { ditemplateId: templateId, diData, diLayout } = vdi;
  
          if(diData) {
  
            const { entityCode } = diData;
            const instanceId = diData[`_${entityCode}Id`];
  
            const { advanced } = diLayout;
            if(advanced) {
              const { auth } = advanced;
              if(auth) {
                const { isAuthEnabled } = auth;
                if(isAuthEnabled) {
  
                  if (!_ukey) {
                    return this.oauthViaRefreshToken(templateId, instanceId);
                  } else { 
                    return this.oauthViaUkey(_ukey, templateId, instanceId);
                  } 
  
                }
  
              }
            }
  
          }
          
  
          return of({});
  
         
        } else {
          return of({});
        }
  
      } else {
        return of({});
      }

    } catch(e) {
      return of({});
    }

   
    
   
  

  }

  /**
   * Get Oauth Data if ukey is present in the url
   * @param _ukey 
   * @param templateId 
   * @param instanceId 
   * @returns 
   */
  oauthViaUkey(_ukey, templateId, instanceId) {

    const params = {
      data: {
        "grantType": "dilogin",
        "userType": "external",
        templateId,
        instanceId,
        "ukey": _ukey
      }
     
    };


    return this.authService.getAuthData$(params).pipe(
      map(({data}) => {

        if(data.hasOwnProperty('accessToken')) {
          this.authService.setSession(templateId, instanceId, data);   
        }
        return data

      }),
      catchError((err) => {
        return of({})
      })
    );

  }

  /**
   * Get oauth data if session exisits
   * @param templateId 
   * @param instanceId 
   * @returns 
   */
  oauthViaRefreshToken(templateId, instanceId) {
    const key = `qlkt-${templateId}-${instanceId}`;
    const sessionData = sessionStorage.getItem(key);
    if(sessionData) {
      const authData = JSON.parse(sessionData);

      const { refreshToken, accessToken } = authData;

      const params = {
        data: {
          "grantType": "refreshToken",
          refreshToken,
          accessToken
        }
       
      };

      return this.authService.getAuthData$(params).pipe(
        map(({data}) => {
  
          if(data.hasOwnProperty('accessToken')) {
            this.authService.setSession(templateId, instanceId, data);   
          }
          
          return data;
  
        }),
        catchError((err) => {
          return of({})
        })
      );


    } else {
      return of({});
    }

  }
}
