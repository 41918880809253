import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-mini';

@Pipe({
  name: 'diDate'
})

export class DiDatePipe implements PipeTransform {

  /**
   * convert dateObject into dateString
   * @param data contains dateObject
   * @param dateFormat contains format
   * @returns string value
   * @author Lalitkumar Saindane
   */

  convertDate(data: any, dateFormat: string): string {
    let dateStr;
    let date;
    let result;

    if (data.hasOwnProperty('month') && data.hasOwnProperty('day') && data.hasOwnProperty('year')
      && data.hasOwnProperty('hour') && data.hasOwnProperty('minute') && data.hasOwnProperty('second')
      && data.hasOwnProperty('fractionalSecond') && data.hasOwnProperty('timezone')) {

      dateStr = data.month + ' ' + data.day + ' ' + data.year + ' ' + data.hour + ':' + data.minute + ':' + data.second +
        + ':' + data.fractionalSecond + ' ' + data.timezone;

      date = new Date(dateStr);
    }

    result = this.formatDate(date, dateFormat);

    return result;

  }

  /**
   * get date&time based on format
   * @param dataStr contains string
   * @param dateFormat contains format
   * @returns string value
   * @author Lalitkumar Saindane
   */

  formatDate(dateStr: string, dateFormat: string): string {

    return moment(dateStr).format(dateFormat);

  }

  /**
   * [transform method to convert date into given format]
   * @return string
   * @author Lalitkumar Saindane
   */

  transform(value: any, format?: string): string {
    let dateFormat = format;
    let result;

    if (!dateFormat) {
      dateFormat = ' MM/DD/YYYY HH:mm:ss ';
    }

    if (typeof value === 'string') {

      result = this.formatDate(value, dateFormat);

    } else {

      result = this.convertDate(value, dateFormat);
    }

    return result;
  }

}
