export const LANGUAGE_CODE_FALLBACKS = {
  "en" : ["eng"],
  "en-sg" : ["ENG_SGP","eng","en"],
  "en-my" : ["ENG_MYS","eng","en"],
  "en-in" : ["ENG_IND","eng","en"],
  "en-hk" : ["ENG_HKG","eng","en"],
  "en-id" : ["eng","en"],
  "en-ph" : ["ENG_PHL","eng","en"],
  "en-bz" : ["eng","en"],
  "en-cb" : ["eng","en"],
  "en-za" : ["ENG_ZAF","eng","en"],
  "en-ie" : ["ENG_IRL","eng","en"],
  "en-nz" : ["ENG_NZL","eng","en"],
  "en-au" : ["ENG_AUS","eng","en"],
  "en-gb" : ["ENG_GBR","eng","en"],
  "en-tt" : ["eng","en"],
  "en-us" : ["en_US","eng","en"],
  "en-zw" : ["eng","en"],
  "fr" : ["fre"],
  "fr-ht" : ["fre","fr"],
  "fr-ma" : ["FRE_MAR","fre","fr"],
  "fr-cm" : ["FRE_CMR","fre","fr"],
  "fr-sn" : ["FRE_SEN","fre","fr"],
  "fr-cg" : ["fre","fr"],
  "fr-west" : ["fre","fr"],
  "fr-mc" : ["fre","fr"],
  "fr-lu" : ["FRE_LUX","fre","fr"],
  "fr-ca" : ["FRE_CAN","fre","fr"],
  "fr-ml" : ["fre","fr"],
  "fr-be" : ["FRE_BEL","fre","fr"],
  "fr-fr" : ["FRE_FRA","fre","fr"],
  "fr-ci" : ["fre","fr"],
  "nl" : ["dut"],
  "nl-nl" : ["DUT_NLD","dut", "nl"],
  "nl-be" : ["dut","DUT_BEL", "nl"],
  "da" : ["dan"],
  "da-dk" : ["dan","DAN_DNK"],
  "de" : ["gmh"],
  "de-at" : ["GMH_AUT","gmh", "de"],
  "de-de" : ["GMH_DEU","gmh", "de"],
  "de-li" : ["gmh", "de"],
  "de-ch" : ["GMH_CHE","gmh", "de"],
  "de-lu" : ["GMH_LUX","gmh", "de"],
  "es" : ["spa"],
  "es-us" : ["spa", "es"],
  "es-pr" : ["spa", "es"],
  "es-ve" : ["SPA_VEN","spa", "es"],
  "es-ni" : ["spa", "es"],
  "es-hn" : ["spa", "es"],
  "es-sv" : ["spa", "es"],
  "es-bo" : ["spa", "es"],
  "es-py" : ["SPA_PRY","spa", "es"],
  "es-uy" : ["SPA_URY","spa", "es"],
  "es-cl" : ["SPA_CHL","spa", "es"],
  "es-ec" : ["SPA_ECU","spa", "es"],
  "es-ar" : ["SPA_ARG","spa", "es"],
  "es-pe" : ["SPA_PER","spa", "es"],
  "es-co" : ["SPA_COL","spa", "es"],
  "es-do" : ["SPA_DOM","spa", "es"],
  "es-pa" : ["SPA_PAN","spa", "es"],
  "es-cr" : ["SPA_CRI","spa", "es"],
  "es-es" : ["SPA_ESP","spa", "es"],
  "es-mx" : ["SPA_MEX","spa", "es"],
  "es-gt" : ["SPA_GTM","spa", "es"],
  "es-es_tradnl" : ["spa", "es"],
  "ar" : ["ara"],
  "ar-qa" : ["ARA_QAT","ara", "ar"],
  "ar-bh" : ["ARA_BHR","ara", "ar"],
  "ar-ae" : ["ARA_ARE","ara", "ar"],
  "ar-kw" : ["ARA_KWT","ara", "ar"],
  "ar-lb" : ["ARA_LBN","ara", "ar"],
  "ar-sy" : ["ara", "ar"],
  "ar-ye" : ["ARA_YEM","ara", "ar"],
  "ar-tn" : ["ARA_TUN","ara", "ar"],
  "ar-ma" : ["ARA_MAR","ara", "ar"],
  "ar-dz" : ["ARA_DZA","ara", "ar"],
  "ar-sa" : ["ARA_SAU","ara", "ar"],
  "ar-iq" : ["ara", "ar"],
  "ar-jo" : ["ARA_JOR","ara", "ar"],
  "ar-eg" : ["ARA_EGY","ara", "ar"],
  "ar-om" : ["ARA_OMN","ara", "ar"],
  "he" : ["heb"],
  "he-il" : ["HEB_ISR","heb"],
  "bg" : ["bul"],
  "bg-bg" : ["BUL_BGR","bul"],
  "hr" : ["hrv"],
  "hr-hr" : ["HRV_HRV","hrv"],
  "hr-ba" : ["HRV_HRV","hrv"],
  "cs" : ["cze"],
  "cs-cz" : ["CZE_CZE","cze"],
  "zh":["chi","zho"],
  "zh-hant" : ["chi","zho"],
  "zh-mo" : ["chi","zho"],
  "zh-tw" : ["ZHO_TWN","chi","zho"],
  "zh-cn" : ["CHI_CHN","chi","zho"],
  "zh-hk" : ["chi","zho"],
  "et" : ["est"],
  "et-ee" :["EST_EST","est"],
  "fi" : ["fin"],
  "fi-fi" : ["FIN_FIN","fin"],
  "ka" : ["geo"],
  "ka-ge" : ["GEO_GEO","geo"],
  "el" : ["gre"],
  "el-gr" : ["GRE_GRC","gre"],
  "hu" : ["hun"],
  "hu-hu" : ["HUN_HUN","hun"],
  "id" : ["ind"],
  "id-id" : ["IND_IDN","ind"],
  "is" : ["ice"],
  "is-is" : ["ICE_ISL","ice"],
  "it" : ["ita"],
  "it-ch" : ["ITA_CHE","ita"],
  "it-it" : ["ITA_ITA","ita"],
  "ja" : ["jpn"],
  "ja-jp" : ["JPN_JPN","jpn"],
  "ko" : ["kor"],
  "ko-kr" : ["KOR_KOR","kor"],
  "lv" : ["lav"],
  "lv-lv" : ["LAV_LAV","lav"],
  "lt" : ["lit"],
  "lt-lt" : ["LIT_LTU","lit"],
  "pl" : ["pol"],
  "pl-pl" : ["POL_POL","pol"],
  "ro" : ["rum"],
  "ro-ro" : ["RUM_ROU","rum"],
  "ro-mo" : ["RUM_MDA","rum"],
  "ru" : ["rus"],
  "ru-ru" : ["RUS_RUS","rus"],
  "ru-mo" : ["rus"],
  "sk" : ["slo"],
  "sk-sk" : ["SLO_SVK","slo"],
  "sl" : ["slv"],
  "sl-si" : ["SLV_SVN","slv"],
  "sv" : ["swe"],
  "sv-fi" : ["SWE_FIN","swe"],
  "sv-se" : ["SWE_FIN","swe"],
  "vi" : ["vie"],
  "vi-vn" : ["VIE_VNM","vie"],
  "sr" : ["srp"],
  "sr-latn-rs" : ["srp"],
  "sr-cyrl-rs" : ["srp"],
  "sr-cyrl-ba" : ["srp"],
  "sr-latn-ba" : ["srp"],
  "sr-cyrl-cs" : ["srp"],
  "sr-latn-cs" : ["srp"],
  "sr-latn-me" : ["srp"],
  "sr-cyrl-me" : ["srp"],
  "sr-cyrl" : ["srp"],
  "sr-latn" : ["srp"],
  "no" : ["nor"],
  "nn-no" : ["nor"],
  "bs" : ["bos"],
  "bs-cyrl-ba" : ["bos"],
  "bs-latn-ba" : ["bos"],
  "bs-cyrl" : ["bos"],
  "bs-latn" : ["bos"],
  "mk" : ["mac"],
  "mk-mk" : ["MAC_KKD"],
  "me" : ["cnr"],
  "wo" : ["wol"],
  "wo-sn" : ["wol"],
  "fa" : ["per"],
  "fa-ir" : ["per"],
  "tr" : ["tur"],
  "tr-tr" : ["tur"],
  "uk" : ["ukr"],
  "uk-ua" : ["UKR_UKR","ukr"]
  };