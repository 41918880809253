
export const DRUG_FACTS = [
    {
        'name': 'Drug Facts',
        'alias': 'drug-facts',
        'icon': ['fas', 'search']

    },
    {
        'name': 'Health & Safety',
        'alias': 'health-and-safety',
        'icon': ['fas', 'exclamation-triangle']
    },
    {
        'name': 'Usage Instructions',
        'alias': 'usage-instructions',
        'icon': ['far', 'hand-point-up']

    },
    {
        'name': 'About this Product',
        'alias': 'about-this-product',
        'icon': ['fas', 'info-circle']
    },
    {
        'name': 'Company, Brand & Sustainability',
        'alias': 'company-brand-and-sustainability',
        'icon': ['fas', 'external-link-square-alt']
    }
];

export const NON_FOOD = [
    {
        'name': 'Ingredients & Contents',
        'alias': 'ingredients-and-contents',
        'icon': ['fas', 'list']
    },
    {
        'name': 'Health, Safety & Environment',
        'alias': 'health-safety-and-environment',
        'icon': ['fas', 'exclamation-triangle']

    },
    {
        'name': 'Usage & Handling',
        'alias': 'usage-and-handling',
        'icon': ['far', 'hand-point-up']

    },
    {
        'name': 'About this Product',
        'alias': 'about-this-product',
        'icon': ['fas', 'info-circle']

    },
    {
        'name': 'Company, Brand & Sustainability',
        'alias': 'company-brand-and-sustainability',
        'icon': ['fas', 'external-link-square-alt']

    }
];

export const FOOD = [
    {
        'name': [
            {
                "languageCode": "en",
                "value": "Nutrition"
            },
            {
                "languageCode": "fr",
                "value": "Nutrition"
            },
            {
                "languageCode": "en_US",
                "value": "Nutrition"
            },
            {
                "languageCode": "en_CA",
                "value": "Nutrition"
            },
            {
                "languageCode": "fr_CA",
                "value": "Nutrition"
            }
        ],
        'alias': 'nutrition',
        'icon': ['fas', 'utensils']
    },
    {
        'name': [
            {
                "languageCode": "en",
                "value": "Ingredients"
            },
            {
                "languageCode": "fr",
                "value": "Ingrédients"
            },
            {
                "languageCode": "en_US",
                "value": "Ingredients"
            },
            {
                "languageCode": "en_CA",
                "value": "Ingredients"
            },
            {
                "languageCode": "fr_CA",
                "value": "Ingrédients"
            }
        ],
        'alias': 'ingredients',
        'icon': ['fas', 'list']

    },
    {
        'name': [
            {
                "languageCode": "en",
                "value": "Allergens"
            },
            {
                "languageCode": "fr",
                "value": "Allergènes"
            },
            {
                "languageCode": "en_US",
                "value": "Allergens"
            },
            {
                "languageCode": "en_CA",
                "value": "Allergens"
            },
            {
                "languageCode": "fr_CA",
                "value": "Allergènes"
            }
        ],
        'alias': 'allergens',
        'icon': ['fas', 'exclamation-triangle']

    },
    {
        'name': [
            {
                "languageCode": "en",
                "value": "About This Product"
            },
            {
                "languageCode": "fr",
                "value": "À propos de ce produit"
            },
            {
                "languageCode": "en_US",
                "value": "About This Product"
            },
            {
                "languageCode": "en_CA",
                "value": "About This Product"
            },
            {
                "languageCode": "fr_CA",
                "value": "À propos de ce produit"
            }
        ],
        'alias': 'about-this-product',
        'icon': ['fas', 'info-circle']

    },
    {
        'name': [
            {
                "languageCode": "en",
                "value": "Company, Brands & Sustainability"
            },
            {
                "languageCode": "fr",
                "value": "Entreprise, marques et durabilité"
            },
            {
                "languageCode": "en_US",
                "value": "Company, Brands & Sustainability"
            },
            {
                "languageCode": "en_CA",
                "value": "Company, Brands & Sustainability"
            },
            {
                "languageCode": "fr_CA",
                "value": "Entreprise, marques et durabilité"
            }
        ],
        'alias': 'company-brand-and-sustainability',
        'icon': ['fas', 'external-link-square-alt']

    }
];

export const SUPPLEMENT = [
    {
        'name': [
            {
                "languageCode": "en",
                "value": "Supplement Facts"
            },
            {
                "languageCode": "fr",
                "value": "Faits Supplément"
            },
            {
                "languageCode": "en_US",
                "value": "Supplement Facts"
            },
            {
                "languageCode": "fr_US",
                "value": "Faits Supplément"
            },
            {
                "languageCode": "en_CA",
                "value": "Supplement Facts"
            },
            {
                "languageCode": "fr_CA",
                "value": "Faits Supplément"
            }
        ],
        'alias': 'supplement-facts',
        'icon': ['fas', 'search']
    },
    {
        'name': [
            {
                "languageCode": "en",
                "value": "Ingredients"
            },
            {
                "languageCode": "fr",
                "value": "Ingrédients"
            },
            {
                "languageCode": "en_US",
                "value": "Ingredients"
            },
            {
                "languageCode": "fr_US",
                "value": "Ingrédients"
            },
            {
                "languageCode": "en_CA",
                "value": "Ingredients"
            },
            {
                "languageCode": "fr_CA",
                "value": "Ingrédients"
            }
        ],
        'alias': 'ingredients',
        'icon': ['fas', 'list']

    },
    {
        'name': [
            {
                "languageCode": "en",
                "value": "Allergens"
            },
            {
                "languageCode": "fr",
                "value": "Allergènes"
            },
            {
                "languageCode": "en_US",
                "value": "Allergens"
            },
            {
                "languageCode": "fr_US",
                "value": "Allergènes"
            },
            {
                "languageCode": "en_CA",
                "value": "Allergens"
            },
            {
                "languageCode": "fr_CA",
                "value": "Allergènes"
            }
        ],
        'alias': 'allergens',
        'icon': ['fas', 'exclamation-triangle']

    },
    {
        'name': [
            {
                "languageCode": "en",
                "value": "About This Product"
            },
            {
                "languageCode": "fr",
                "value": "À propos de ce produit"
            },
            {
                "languageCode": "en_US",
                "value": "About This Product"
            },
            {
                "languageCode": "en_CA",
                "value": "About This Product"
            },
            {
                "languageCode": "fr_CA",
                "value": "À propos de ce produit"
            }

        ],
        'alias': 'about-this-product',
        'icon': ['fas', 'info-circle']

    },
    {
        'name': [
            {
                "languageCode": "en",
                "value": "Company, Brands & Sustainability"
            },
            {
                "languageCode": "fr",
                "value": "Entreprise, marques et durabilité"
            },
            {
                "languageCode": "en_US",
                "value": "Company, Brands & Sustainability"
            },
            {
                "languageCode": "fr_US",
                "value": "Entreprise, marques et durabilité"
            },
            {
                "languageCode": "en_CA",
                "value": "Company, Brands & Sustainability"
            },
            {
                "languageCode": "fr_CA",
                "value": "Entreprise, marques et durabilité"
            }
        ],
        'alias': 'company-brand-and-sustainability',
        'icon': ['fas', 'external-link-square-alt']

    }
];
