<ng-template [ngIf]="config && config.type === 'dropdown'">

  <ion-grid fixed class="ion-fixed fixed">
    <ion-row justify-content-center class="justify-content-center ion-justify-content-center">
      <ion-col align-self-center size="6" class="align-self-center ion-align-self-center">

        <select [(ngModel)]="selectedValue" (change)="onChange(selectedValue)" name="{{config.name}}"
          *ngIf="config.options.length" [ngClass]="config.class">
          <option *ngFor="let option of config.options" value="{{option.value}}">{{option.label}}</option>
        </select>

      </ion-col>
    </ion-row>
  </ion-grid>

</ng-template>

<ng-template [ngIf]="options$">
  <ion-list>
    <!-- <ion-searchbar *ngIf="formattedLangOptions.length" [formControl]="searchControl"></ion-searchbar> -->

    <div *ngIf="options$ | async as options; else loading">
      <ion-item tappable *ngFor="let language of options" (click)="onSelect(language.code)">
        <ion-label>{{language.name | multiLang: 'en' }}</ion-label>
        <ion-icon name="checkmark" slot="end" color="primary"
          *ngIf="language.code?.toLowerCase() === currentLang?.toLowerCase()"></ion-icon>
        <!-- <ion-checkbox ></ion-checkbox> -->
      </ion-item>
      <ion-item *ngIf="!options.length" lines="none">
        <ion-label color="medium">No Language Found.</ion-label>
      </ion-item>
      <ion-item-divider color="dark"></ion-item-divider>
      <ion-item tappable lines="none" (click)="onSelect(defaultLang)">
        <ion-label>Switch to default</ion-label>
      </ion-item>
    </div>
  </ion-list>
</ng-template>
<ng-template #loading>
  <ion-spinner name="crescent"></ion-spinner>
</ng-template>