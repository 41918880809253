
import { ChangeDetectorRef, Injectable } from '@angular/core';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

import { BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class NftService {

  private _state;
  public connector;
  public isWalletConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public openseaAPIEndpoint = 'https://api.opensea.io/api/v1/'; //https://testnets-api.opensea.io/api/v1/
  private _walletAddress = null


  get state() {
    return this._state;
  }

  set state(state) {
    this._state = { ...state };
  }

  get walletAddress() {
    return this._walletAddress;
  }

  set walletAddress(addr) {
    this._walletAddress = addr;
  }


  constructor(private apiService: ApiService) { }

  /**
   * Initialize WalletConnect v1
   * @author Neil C
   */
  init() {
    //console.log('nft service init', this.connector)
    //DEBUG
    // if (location.origin.indexOf('localhost') != -1) {
    //   this.openseaAPIEndpoint = `https://testnets-api.opensea.io/api/v1/`;
    // }
    this.openseaAPIEndpoint = `https://testnets-api.opensea.io/api/v1/`;

    this.state = { connected: false }

    this.connector = this.getConnector();

    if (this.connector.connected) {

      this.state = { ...this.connector.session }
      this.isWalletConnected$.next(true);

      this.walletAddress = this.getWalletAddress();
      //this.connector.killSession();

    }

    this.subscribeToEvents();



  }

  /**
   * 
   * @returns 
   */
  getConnector() {

    return new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      qrcodeModal: QRCodeModal,
    });
  }

  /**
   * Generate Wallet Connect modal 
   * @author Neil C
   */
  connect() {
    this.connector = this.getConnector();

    if (!this.connector.connected) {
      this.connector.createSession();

      this.subscribeToEvents();
      this.walletAddress = this.getWalletAddress();

    }
  }


  /**
   * Wallet events subscriptions
   * @author Neil C
   */
  subscribeToEvents() {
    //console.log('subscribe to events', this.connector);
    this.connector.on('connect', (error, { params }) => {
      console.log('on connect');
      this.state = { ...this.connector.session }
      this.walletAddress = this.getWalletAddress();
      this.isWalletConnected$.next(true);

    });

    this.connector.on('disconnect', (error, data) => {
      console.log('on disconnect');
      this.state = null;
      this.isWalletConnected$.next(false);
      this.connector.killSession();
    });

    this.connector.on('session_update', (error, { params }) => {
      this.state = { ...this.connector.session }
      console.log('on session_update');
      const walletAddress = this.getWalletAddress(); //if wallet address is the same no need to refetch
      if (walletAddress != this.walletAddress) {
        this.walletAddress = walletAddress
        this.isWalletConnected$.next(true);
      }

    });


  }

  /**
   * 
   * @param asset_contract_address 
   * @param token_id 
   * @returns 
   */
  getAsset(asset_contract_address, token_id, account_address) {
    const path = `${this.openseaAPIEndpoint}asset/${asset_contract_address}/${token_id}?account_address=${account_address}`;

    return this.apiService.getByPath(path)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      )

  }

  getWalletAddress() {
    const [address] = this.connector.session.accounts;
    return address;
  }






}
