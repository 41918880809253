<!-- <ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()" fill="clear" title="Close">
          <ion-icon name="close-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
   
  </ion-toolbar>
</ion-header> -->


<ion-content fullscreen>

  <!-- <ion-grid>
    <ion-row>
      <ion-col size="2">
       
      </ion-col>

      <ion-col>
        <ion-text>
          <h3 style="margin: 0;">{{prompt}}</h3>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid> -->

  

   

  <form [formGroup]="form">
    <ion-list formArrayName="topics" *ngIf="topics.length > 0" lines="full"> 

      <ion-item lines="none">
        <ion-icon name="notifications" size="large" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">{{prompt}}</ion-label>
      </ion-item>


      <ion-item *ngFor="let topic of topics; let i = index" [disabled]="isTopicLoading(topic.topicCode)">

       
        <ion-checkbox [formControlName]="i" [value]="topic.topicCode" slot="start" color="success" mode="ios" (click)="updateSubscription($event)" [hidden]="isTopicLoading(topic.topicCode)" slot="start"></ion-checkbox>
         
        <div *ngIf="isTopicLoading(topic.topicCode)" slot="start"> 
          <ion-spinner name="crescent" color="primary" *ngIf="isTopicLoading(topic.topicCode)"  ></ion-spinner>
        </div>
       

        
        <ion-label class="ion-text-wrap">
          {{topic.description}}
        </ion-label>
      </ion-item>
    </ion-list>
  </form>






</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()" fill="clear" size="large">Cancel</ion-button>

      <ion-spinner name="dots" color="primary" *ngIf="inProgress"></ion-spinner>

      <ion-button (click)="onSubmit()" fill="solid" color="primary" size="large" *ngIf="!permissionGranted && !inProgress">
       
        Allow
      </ion-button >
    </ion-buttons>
  </ion-toolbar>
</ion-footer>