<ion-header *ngIf="config">
  <ion-toolbar #toolbar [ngClass]="config.class">
    <!-- Back Button And Menu Button-->
      <ion-buttons slot="start" *ngIf="isMenuButtonVisible" >
        <ion-menu-toggle>
          <ion-button primary  [attr.style]="sanitizer.bypassSecurityTrustStyle('--color: ' + config.color)">
            <ion-icon icon-only name="menu"></ion-icon>
          </ion-button>
        </ion-menu-toggle>
      </ion-buttons>

      <ng-template [ngIf]="config?.logo.url">
        <ion-img [src]="config.logo.url" [slot]="config.logo.align"
        [style.width.px]="config.logo.width"
        [style.height.px]="config.logo.height"
        [class.centered]="config.logo.align===''"
        ></ion-img>
      </ng-template>


     
    
        <ion-title #headerTitle [innerHTML]="title" [slot]="config.headerText.align"  [class.ion-text-center]="config.headerText.align===''"
        *ngIf="title !=''"
        >
        </ion-title> 
    

      <ion-button *ngIf="config?.showLanguageSelector" slot="end" (click)="presentPopover($event)" fill="clear" [attr.style]="sanitizer.bypassSecurityTrustStyle('--color: ' + config.color)">
        <ion-icon name="globe"></ion-icon>
        <ion-icon name="arrow-dropdown" size="small"></ion-icon>
      </ion-button>

      <ion-button slot="end" *ngIf="authService.isLoggedIn == true" (click)="logout()" fill="clear" [attr.style]="sanitizer.bypassSecurityTrustStyle('--color: ' + config.color)" title="Logout">
        <ion-icon name="log-out" *ngIf="!isLogoutInProgress"></ion-icon>
        <ion-spinner name="dots" *ngIf="isLogoutInProgress"></ion-spinner>
      </ion-button>

      <ion-button *ngIf="pushNotificationsService.isSupported" slot="end"  fill="clear" [attr.style]="sanitizer.bypassSecurityTrustStyle('--color: ' + config.color)" (click)="openPushNotificationsModal()">
        <ion-icon name="notifications"></ion-icon>
      </ion-button>
  
    </ion-toolbar>
</ion-header>
