import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { PageService } from '../services/page.service';

@Pipe({
  name: 'code2txt'
})

@Injectable()
export class Code2txtPipe implements PipeTransform {

  constructor(private pageService: PageService) {
  }

   /**
   * replace text based on code Legend. if not found return same text
   * @param str contains string
   * @param data contains codeLegend
   * @returns string value
   * @author Lalitkumar Saindane
   */
  getValue (str: string, data: object, languageCode?: string): string {

    let result;
    let key;

    key = str.substring(1 , str.length - 1);

    if (data.hasOwnProperty(key)) {
       result =  this.multilang(data[key], languageCode);
    } else {
       result = key;
    }

    return result;
  }

   /**
   * get text based on language code
   * @param data contains array
   * @param languageCode contains languageCode
   * @returns string value
   * @author Lalitkumar Saindane
   */
  multilang(data: Array<any>, languageCode?: string): string {
    let code = languageCode;
    let result;

     if (!code) {
       code = this.pageService.appLang$.getValue();
     }

    if (Array.isArray(data)) {
        result =  data.filter((item) => item.languageCode === code).shift();

      if (result) {
        result = result.value;
      }

    } else {
       result = data;
    }

  return result;
  }

  /**
   * [transform method to replace text based on codeLegend]
   * @return any
   * @author Lalitkumar Saindane
   */

  transform(str: any, codeLegend: object, languageCode?: string): any {

    const rgxValue = '(\%)+([a-z|A-Z|_|0-9])+(\%)';
    let filter, matches;

    if (typeof str === 'string' && codeLegend ) {
      filter = new RegExp(rgxValue, 'g');

      matches = str.match(filter);

      if (Array.isArray(matches)) {

        for (const item of matches) {
          str = str.replace(str.match(item), this.getValue(item, codeLegend, languageCode));
         }

      }

    }

    return str;
  }

}
