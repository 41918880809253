import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  config: any = {

  };
  isSupported: boolean = false; //PUSH NOTIFICATIONS BROWSER SUPPORT
  isPushNotificationModalOpen:boolean = false 
  constructor() { }


  
}
