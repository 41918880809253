import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { decompressWebUri, isCompressedWebUri } from '../../assets/js/GS1DigitalLinkCompression';
import { ApiService } from './api.service';
import { PageService } from './page.service';

enum NFCTypes {
  hid = 'hid_trusted_tag',
  ntag = 'ntag_424_dna'
}
const nfcMockObject = {
  data: {
    authenticityStatus: false,
    authenticityCode: '0010',
    description: ''
  }
};
@Injectable({
  providedIn: 'root'
})
export class DataService {
 
  route;
  constructor(
    private apiService: ApiService,
    private pageService: PageService
  ) { }

  /**
   * Get DI data 
   * @author Neil C 
   * @task QLIKTAG-4087
   * @param activatedRouteSnapshot 
   * @returns 
   */
  getData(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<any> {
    console.log(activatedRouteSnapshot);
    if(!activatedRouteSnapshot) return of(null); //NOTE CHECK RETURN TYPE

    this.route = activatedRouteSnapshot

    const { params, queryParams, data, children: childRoutes } = activatedRouteSnapshot;
    const { sandbox = false, linkTypeId, linkType } =  queryParams;

    if(sandbox) return of(null); //NOTE CHECK RETURN TYPE

    let { iid, eid, shortcode } = params;

    if(!iid) {
      iid =  queryParams.iid ? queryParams.iid : null;
    }

    if(!eid || eid === 'p') {
      eid =  queryParams.eid ? queryParams.eid : null;
    }

    const { itype } = data;

    if(itype === 'lp') {

      const params: any = {};
      if (iid && (eid || queryParams)) { // OR ADDED BY NEILC eid can be optional
        params.iid = iid;
        params.eid = eid;
        return this.getDI(params, queryParams);
      }

    } else if(itype === 'dl') {

      const params: any = {};
      if (eid && linkTypeId) {
        params.eid = eid;
        params.iid = linkTypeId;
        return this.getDI(params, queryParams);
      }

    } else {

        //SHORTCODE + P/ALIAS
    
        //SHORTCODE + linkTypeID

        //COMPRESSED LINK

        //SHORTCODE
        const [ childRoute ] = childRoutes;
        const { params: cparams } = childRoute;
        const { alias } = cparams;
        const pathname = location.pathname;
        const url = location.toString();
        // console.log('isCompressed', isCompressedWebUri(url));
        // console.log({pathname})
        //console.log(pathname.split("/"));
        const pathnameArr = pathname.split("/").filter(val => val!="");

        //console.log({pathnameArr})

        const body: Params = {};
        if(shortcode && linkTypeId) { //<instance-id>?linkTypeId=<template-id>
          body.eid = shortcode;
          body.iid = linkTypeId;
          // console.log('getDI');
          return this.getDI(body, queryParams);
          //console.log({result})
        } else if(shortcode && alias) { //<short-code>/p/page-name
          body.shortcode = shortcode;
          return this.getDIByShortcode(params, queryParams);
        } else if(pathnameArr.length % 2 == 0) { //<k1>/<v1>/<k2>/<v2>?linkType=<link-type>
          this.pageService.isDigitalLinkInteraction = true;
          const dlparams = {};

          pathnameArr.forEach((value, index) => {
            if(index %2 == 0) {
              if(value != 'p') {
                dlparams[value] = pathnameArr[index+1]
              }

             

            }
          });
         
        
         return this.getDIByDigitalLink(body, { ...queryParams, ...dlparams });
         
        } else if(isCompressedWebUri(url)) { //<compressed-digital-link>
          const decompressedURL = decompressWebUri(url);
          decompressedURL ? location.href = decompressedURL : null
        } else { //<short-code>
          body.shortcode = shortcode;
          return this.getDIByShortcode(params, queryParams);
        }
        
        //DEBUG
        // console.log({route});
        // console.log({params: this.route.params});
        // console.log({children:this.route.children})
        // console.log({state})
        // console.log(state.url)

    }

    return of({});

    console.log({iid , sandbox, eid, itype})
    
  }



 /**
   * getDI - wraps apiService.getDI and concat with NFC call
   * @param params
   * @param queryParams
   * @author - SwapnilP
   * @task - QLIKTAG-3894
   */
  private getDI(params, queryParams) {
    return this.apiService.getDI(params, queryParams)
      .pipe(
        map((vdi) => {
          return vdi;
        }),
        concatMap(vdi => {
          const { diLayout, diData } = vdi;
          return this.getNFCData(diLayout, diData).pipe(
            map(nfc => {
              const result = {
                vdi,
                ...(nfc ? { nfc } : {})
              };
              return result;
            })
          );
        })
      );
  }


   /**
   * Check conditions and make nfc validation call.
   * @param route
   * @param vdi
   * @author - SwapnilP
   * @task - QLIKTAG-3894
   */
    getNFCData(diLayout: { [key: string]: any } = {}, diData: { [key: string]: any } = {}): Observable<any> {
      // console.log('GET NFC DATA');
      // console.log(diLayout);
      // Params
      const { advanced = null } = diLayout || {} ;
      if (advanced) {
        const { nfc: { isNFCEnabled = false, nfcAuthentication = null } = {} } = advanced;
        if (isNFCEnabled && nfcAuthentication) {
          const { type, hidTagDetails, nTagDetails } = nfcAuthentication;
          if (type === NFCTypes.hid && hidTagDetails) {
            const { hidUsername, hidPassword, url } = hidTagDetails;
            if (hidUsername && hidPassword) {
              const { tac, tagID } = this.route.queryParams;
              if (tac && tagID) {
                const body = {
                  data: {
                    type: type,
                    hidTagDetails: {
                      tac,
                      tagID,
                      hidUsername,
                      hidPassword,
                      ...(url ? { url: url } : {})  // dont send if falsy
                    }
                  }
                };
                return this.nfcValidate(body).pipe(
                  map(response => {
                    return { nfc: { [NFCTypes.hid]: response } };
                  })
                );
              }
            }
          } else if (type === NFCTypes.ntag && nTagDetails) {
            const { tagIdAttributeJsonPath, piccKey, cmacKey } = nTagDetails;
            const piccData = this.route.queryParams[piccKey];
            const cmac = this.route.queryParams[cmacKey];
            const { entityCode } = diData;
            const { [`_${entityCode}Id`]: entityInstanceId } = diData;
            if (piccData && cmac && entityCode && entityInstanceId) {
              const body = {
                data: {
                  type: type,
                  nTagDetails: {
                    ...(tagIdAttributeJsonPath ? { tagIdAttributeJsonPath } : {}),
                    piccData,
                    cmac,
                    entityCode,
                    entityInstanceId
                  }
                }
              };
              return this.nfcValidate(body).pipe(
                map(response => {
                  return { nfc: { [NFCTypes.ntag]: response } };
                })
              );
            }
          }
        }
      }
      return of(null);
    }

  /**
   * NFC validate API call.
   * @param body
   * @author - SwapnilP
   * @task - QLIKTAG-3894
   */
  nfcValidate(body = {}) {
    // console.log('NFC VALDATE');
    return this.apiService.post('util/security/authenticity/validation', body).pipe(
      map(response => {
        if (response.hasOwnProperty('data')) {
          return { data: response.data };
        } else {
          return nfcMockObject;
        }
      }),
      catchError(error => {
        console.log(error);
        return of(nfcMockObject);
      }));
  }


  /**
   * getDIByShortcode - wraps apiService.getDIByShortcode and concat with NFC call
   * @param params
   * @param queryParams
   * @author - SwapnilP
   * @task - QLIKTAG-3894
   */
   private getDIByShortcode(params, queryParams) {
    return this.apiService.getDIByShortCode(params, queryParams)
      .pipe(
        map((vdi) => {
          return vdi;
        }),
        concatMap(vdi => {
          const { diLayout, diData } = vdi;
          return this.getNFCData(diLayout, diData).pipe(
            map(nfc => {
              const result = {
                vdi,
                ...(nfc ? { nfc } : {})
              };
              return result;
            })
          );
        })
      );
  }


  /**
   * getDIByDigitalLink - 
   * @param params
   * @param queryParams
   */
   private getDIByDigitalLink(params: Params = {}, queryParams: Params = {}) {
    return this.apiService.getDIByDigitalLink(params, queryParams)
      .pipe(
        map((vdi) => {
          return vdi;
        }),
        concatMap(vdi => {
          const { diLayout, diData } = vdi;
          return this.getNFCData(diLayout, diData).pipe(
            map(nfc => {
              const result = {
                vdi,
                ...(nfc ? { nfc } : {})
              };
              return result;
            })
          );
        })
      );
  }





}
