import { Pipe, PipeTransform } from '@angular/core';
import { PageService } from '../services/page.service';

@Pipe({
  name: 'assetType'
})
export class AssetTypePipe implements PipeTransform {

  constructor(public pageService: PageService) { }

  transform(value: any, ...args: any[]): any {

    let result: any = '';

    let type = typeof value;

    if (!value) {
      return ''
    }

    if (type == 'string' || type == 'boolean' || type == 'number') {
      result = value;
    } else {

      if (Array.isArray(value)) {
       
        if (value.length > 0) {

       
          const asset = this.findAsset(value);
          result = this.getValue(asset);

        }

      } else {
        result = this.getValue(value);
      }




    }



    return result;
  }

  /**
   * @author Neil C
   * @task QLIKTAG-3785
   * @param item Asset type object (with languageCode, url, type properties) 
   * @param code? Optional languageCode override
   */
  getValue(item: any, languageCode?: string): string {
    let value = '';
    if (!languageCode) {
      languageCode = this.pageService.appLang$.getValue() || 'en';
    }

    if (item.hasOwnProperty('languageCode') &&
      item.hasOwnProperty('url') &&
      item.hasOwnProperty('type')) {

      if (Array.isArray(item.languageCode) && item.languageCode.length > 0) {

        let lcExistsFn = (haystack, needle) => {
          return haystack.some((i) => i.toLowerCase() === needle.toLowerCase());
        }

        if (lcExistsFn(item.languageCode, languageCode)) {
          value = item.url;
        }
      }



    }



    return value;
  }

  /**
   * Search Asset type array to located at least 1 entry that has match in languageCode array
   * @param assets 
   */
  findAsset(assets) {

    let [value] = assets; //fallback
    
    const foundAsset = assets.find(
      ({languageCode}) => languageCode && languageCode.some(
        (code) =>  code.toLowerCase() == this.pageService.appLang$.getValue().toLowerCase()
      ));

    if(foundAsset) {
      return foundAsset;
    } else {
      return value;
    }
  }

}
