import { Pipe, PipeTransform, Injectable} from '@angular/core';
import { PageService } from '../services/page.service';
@Pipe({
  name: 'multiLang'
})

@Injectable()
export class MultiLangPipe implements PipeTransform {

  constructor(private pageService: PageService) {
  }


   /**
   * get text based on language code. if not found return en or first array item value
   * @param value contains array
   * @param code contains languageCode
   * @param combineIfMultiple: T/F if multiple values are available for the same languageCode
   * @returns string value
   * @author Lalitkumar Saindane
   */
  getValue (value: Array<any>, code: string, combineIfMultiple: boolean): string {
    let result = '';
    code = code.toLowerCase();
    if (value.length > 0) {
      const items = value
        .filter(item => item.hasOwnProperty('languageCode') && typeof item.languageCode === 'string')
        .filter((item) => item.languageCode.toLowerCase() === code)
        .map((item) => {
          if (item.hasOwnProperty('value') && item.value) {
            return item.value;
          } else {
            return '';
          }
        })
        .filter(item => item);
        
        if (items.length) {
          if (combineIfMultiple) {
            result = items.join('<br/>');
          } else {
            [result] = items;
          }
        }
      }
    return result;
  }

 /**
   * [transform method to filter array of value based on languageCode]
   * @param combineIfMultiple: T/F if multiple values are available for the same languageCode
   * @return string | number | boolean
   * @author Lalitkumar Saindane
   */
  transform(
    value: any,
    languageCode: string = this.pageService.appLang$.getValue(),
    combineIfMultiple: boolean = false): string | number | boolean {
      let result: string | number | boolean = '';
      const code = languageCode;
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' ) {
          result = value;
        } else {
          if (Array.isArray(value)) {
            result = this.getValue(value, code, combineIfMultiple);
          }
        }
      return result;
   }

}
