import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SwUpdate } from '@angular/service-worker';
import { AppService } from './services/app.service';
import { forkJoin } from 'rxjs';
import { PageService } from './services/page.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  isSandboxMode: boolean = false;
  unsupported: boolean = false;
  searchConfig = { separateWordSearch: false };
  constructor(
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,

    public appService: AppService,
    private cdr: ChangeDetectorRef,
    private pageService: PageService,
    private activatedRoute: ActivatedRoute

  ) {
    this.initializeApp();
    this.appService.isLoading = true;


  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // console.log('Start');
        this.appService.isLoading = true;
      } else if (event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError) {
        this.appService.isLoading = false;
        // console.log('END');
      }
    });
  }

  ngOnInit() {
    const IEversion = this.appService.getInternetExplorerVersion();
    if (IEversion > 0) {
      this.unsupported = true;
    }


    this.appService.isSandboxMode.subscribe(mode => {
      //  console.log(mode);
      this.isSandboxMode = mode
      this.cdr.detectChanges();
    });


    forkJoin(
      this.appService.getAvailableLanguages(),
      this.appService.getTrans(),
    ).subscribe(([languages = [], translations = []]) => {
      //console.log({languages, translations})
      this.pageService.appLanguages = languages;
      this.pageService.translations = translations;

      //console.log('Router Details',this.router, this.activatedRoute);
      //console.log('Router Snap shot',  this.activatedRoute.snapshot)

    }, (err) => {
      console.log(err)
    })




  }




}
