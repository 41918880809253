<ng-template [ngIf]="!appService.isLoading">

  <ion-menu side="start" type="overlay"
    *ngIf="pageService.diLayout?.pages?.length > 1 && pageService.navigationType == '1'" contentId="main-content">
    <ion-header padding-start class="ion-padding-start padding-start">
      <ion-toolbar>
        Menu
      </ion-toolbar>
    </ion-header>
    <ion-content>

      <ion-list class="hambuger_menu">
        <ng-container *ngFor="let page of pageService.diLayout.pages">
          <ion-item [ngClass]="page.alias == pageService.pageAlias ? 'active' : ''" (click)="goToPage(page.alias)">
            <span [innerHtml]="pageService.menuIcon(page.icon) | safe: 'html'" class="m-r-10"></span>
            {{page.title | multiLang : pageService.appLang$.getValue()}}
          </ion-item>
        </ng-container>
      </ion-list>

    </ion-content>
  </ion-menu>

  <app-main-header *ngIf="pageService.headerControl?.ctrlName === 'main-header'"
    [config]="pageService.headerControl.config">
  </app-main-header>



  <!-- Ion Content to allow nested views like angular -->
  <ion-content>
    <ion-searchbar class="searchbox" *ngIf="showSearchBox" (ionChange)="searchtextChange($event)"></ion-searchbar>
    <!-- <router-outlet main id="main-content"></router-outlet> -->

    <!-- Fab-Action Sheet Menu -->

    <!-- COMMENTED BY NEIL C 26/6/19 -->

    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="pageService.diLayout?.pages && pageService.diLayout?.pages.length > 1 && pageService.navigationType == '2'">
        <ion-fab-button (click)="showMenuActionSheet()" [activated]="closeActionSheetMenu"  #fab [style.--background]="'pink'">
          <ion-icon name="menu"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="end"></ion-fab-list>
      </ion-fab> -->




  </ion-content>
  <router-outlet main id="main-content"></router-outlet>

  <ng-template
    [ngIf]="pageService.diLayout?.pages && pageService.diLayout?.pages.length > 1 && pageService.navigationType == '2'">
    <app-fab [menuItems]="actionSheetMenuItems" [config]="actionSheetMenuConfig"></app-fab>
  </ng-template>

  <app-main-footer *ngIf="pageService.footerControl?.ctrlName === 'main-footer'"
    [config]="pageService.footerControl.config">
  </app-main-footer>
</ng-template>